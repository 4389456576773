@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
body {
  margin: 0;
  font-family: Montserrat;
  font-weight: 500;
  color: #000;
  overflow-x: hidden; }

/* width */
::-webkit-scrollbar {
  width: 0.6rem;
  max-height: 69px; }

/* Track */
::-webkit-scrollbar-track {
  opacity: 0; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 7px; }

.Section {
  width: 100%; }
  .Section__Content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 40px; }
    @media (max-width: 640px) {
      .Section__Content {
        padding: 0 10px; } }

.PrimaryButton {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  background-color: #2b29a4;
  width: fit-content;
  padding: 24px 40px;
  border: none;
  cursor: pointer;
  transition: 0.1s; }
  .PrimaryButton:hover {
    background-color: #1f1e75; }

.NavBar {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  margin-bottom: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: 0.2s;
  z-index: 99; }
  .NavBar--sticky {
    background-color: #fff; }
  .NavBar__Title {
    font-size: 32px;
    font-weight: 700;
    color: #2b29a4; }
  .NavBar__Subtitle {
    font-size: 16px;
    font-weight: 400; }
  .NavBar__Menu {
    display: flex;
    list-style: none;
    align-items: center;
    gap: 40px;
    margin: 0; }
    @media (max-width: 768px) {
      .NavBar__Menu {
        display: none; } }
    .NavBar__Menu li {
      display: flex;
      height: 100%; }
      .NavBar__Menu li a {
        text-decoration: none;
        color: #000;
        font-size: 16px; }
        .NavBar__Menu li a p {
          transition: 0.2s;
          border-bottom: #ffffff00 1px solid; }
        .NavBar__Menu li a:hover {
          color: #2b29a4; }
          .NavBar__Menu li a:hover p {
            border-bottom: #2b29a4 1px solid; }

.Header {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 618px;
  margin-bottom: 120px; }
  @media (max-width: 1024px) {
    .Header {
      flex-direction: column;
      align-items: center;
      gap: 40px; } }
  @media (max-width: 640px) {
    .Header {
      min-height: 0px; } }
  .Header__TextContainer {
    margin-top: 80px;
    width: 100%;
    max-width: 565px; }
    @media (max-width: 1024px) {
      .Header__TextContainer {
        margin-top: 20px; } }
  .Header__Title {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 35px; }
    @media (max-width: 640px) {
      .Header__Title {
        font-size: 36px;
        margin-bottom: 20px; } }
    @media (max-width: 420px) {
      .Header__Title {
        font-size: 28px; } }
  .Header__Subtext {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 60px; }
    @media (max-width: 1024px) {
      .Header__Subtext {
        margin-bottom: 40px; } }
    @media (max-width: 640px) {
      .Header__Subtext {
        font-size: 18px; } }
  @media (max-width: 1024px) {
    .Header .PrimaryButton {
      display: block;
      font-size: 18px;
      margin: auto; } }
  .Header__Circle {
    position: absolute;
    z-index: -1;
    top: -200px;
    left: -90px;
    width: 550px;
    height: 550px; }
    @media (max-width: 640px) {
      .Header__Circle {
        width: 400px;
        height: 400px; } }
  .Header .ImageContainer {
    position: relative;
    margin-right: 100px;
    margin-left: 40px;
    transform: scale(100%); }
    .Header .ImageContainer__Image {
      width: 100%;
      max-width: 430px;
      aspect-ratio: 43/50; }
    .Header .ImageContainer__Box {
      position: absolute;
      z-index: -1;
      width: 100%;
      max-width: 430px;
      aspect-ratio: 43/50; }
      .Header .ImageContainer__Box:nth-child(2) {
        top: 20px;
        left: 20px;
        margin-right: 80px;
        border: 1px solid #777; }
        .Header .ImageContainer__Box:nth-child(2) .InnerBox {
          position: absolute;
          width: 30px;
          height: 30px;
          bottom: -10px;
          right: -10px;
          background-color: #2b29a4; }
      .Header .ImageContainer__Box:nth-child(3) {
        top: 40px;
        left: 40px;
        margin-right: 60px;
        border: 1px solid #ababab; }
      .Header .ImageContainer__Box:nth-child(4) {
        top: 60px;
        left: 60px;
        margin-right: 40px;
        border: 1px solid #bebebe; }
        .Header .ImageContainer__Box:nth-child(4) .InnerBox {
          position: absolute; }
          .Header .ImageContainer__Box:nth-child(4) .InnerBox:nth-child(1) {
            width: 30px;
            height: 30px;
            bottom: 0;
            right: 0;
            background-color: #000; }
          .Header .ImageContainer__Box:nth-child(4) .InnerBox:nth-child(2) {
            width: 40px;
            height: 40px;
            bottom: 50px;
            right: -40px;
            background: #6b5acd;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: 700; }
    .Header .ImageContainer__TrioBox {
      position: relative;
      bottom: 35px;
      left: -40px;
      z-index: -1; }
      .Header .ImageContainer__TrioBox .InnerBox {
        position: absolute; }
        .Header .ImageContainer__TrioBox .InnerBox:nth-child(1) {
          width: 100px;
          height: 100px;
          background-color: #2b29a4; }
        .Header .ImageContainer__TrioBox .InnerBox:nth-child(2) {
          top: 100px;
          left: 100px;
          width: 50px;
          height: 50px;
          background-color: #000; }
        .Header .ImageContainer__TrioBox .InnerBox:nth-child(3) {
          top: 70px;
          left: 150px;
          width: 30px;
          height: 30px;
          background-color: #d6e6fe; }

footer {
  display: flex;
  justify-content: center;
  align-items: center; }
  footer p {
    font-size: 14px;
    font-weight: 500;
    margin: 24px; }

form {
  display: flex;
  flex-direction: column;
  gap: 16px; }
  form input {
    box-sizing: border-box;
    outline: none;
    font-size: 18px;
    padding: 10px; }
  form textarea {
    font-family: Montserrat;
    box-sizing: border-box;
    outline: none;
    resize: none;
    font-size: 18px;
    height: 200px;
    padding: 8px; }

.Tjenester {
  padding-top: 80px; }
  .Tjenester__Title {
    font-size: 40px;
    font-weight: 700;
    line-height: 58px;
    margin-bottom: 16px; }
    @media (max-width: 768px) {
      .Tjenester__Title {
        font-size: 32px;
        line-height: 42px; } }
    @media (max-width: 640px) {
      .Tjenester__Title {
        font-size: 26px;
        line-height: 32px; } }
  .Tjenester__Subtext {
    color: #4c4c4c;
    font-size: 20px;
    font-weight: 500;
    line-height: 35px;
    max-width: 600px; }
  .Tjenester__CardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 90px 40px;
    margin-top: 100px; }
    @media (max-width: 1024px) {
      .Tjenester__CardContainer {
        gap: 75px 20px; } }
    @media (max-width: 768px) {
      .Tjenester__CardContainer {
        grid-template-columns: 1fr; } }
    @media (max-width: 420px) {
      .Tjenester__CardContainer {
        gap: 40px;
        margin-top: 40px; } }
  .Tjenester__Card {
    position: relative;
    display: flex;
    height: 120px;
    width: 100%;
    max-width: 580px;
    border: 1px solid #b6b6b6;
    align-items: center;
    justify-content: space-between;
    justify-self: center; }
    @media (max-width: 420px) {
      .Tjenester__Card {
        height: 100px; } }
  .Tjenester__CardImg {
    height: 200px;
    align-self: end;
    margin-bottom: -17px; }
    @media (max-width: 1024px) {
      .Tjenester__CardImg {
        height: 175px; } }
    @media (max-width: 768px) {
      .Tjenester__CardImg {
        height: 200px; } }
    @media (max-width: 420px) {
      .Tjenester__CardImg {
        height: 150px;
        margin-bottom: -14px; } }
  .Tjenester__CardText {
    color: #4c4c4c;
    font-size: 16px;
    font-weight: 700;
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px; }
    @media (max-width: 420px) {
      .Tjenester__CardText {
        font-size: 14px; } }
  .Tjenester__MoreText {
    color: #4c4c4c;
    font-size: 20px;
    font-weight: 500;
    line-height: 35px;
    margin-top: 120px;
    padding-top: 120px;
    border-top: solid 1px #000; }
    @media (max-width: 768px) {
      .Tjenester__MoreText {
        font-size: 16px;
        line-height: 28px;
        margin-top: 60px;
        padding-top: 60px; } }
    @media (max-width: 640px) {
      .Tjenester__MoreText {
        font-size: 16px;
        line-height: 28px; } }
  .Tjenester__MoreTitle {
    color: #000;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 40px; }
    @media (max-width: 768px) {
      .Tjenester__MoreTitle {
        font-size: 32px;
        line-height: 42px; } }
    @media (max-width: 640px) {
      .Tjenester__MoreTitle {
        font-size: 26px;
        line-height: 32px; } }
  .Tjenester__ExpandArrow {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    transition: 0.5s; }
    .Tjenester__ExpandArrow--active {
      transform: scaleY(-100%); }
  .Tjenester__ExpandButton {
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px; }

.OmMeg {
  margin: 120px auto;
  display: flex;
  justify-content: center;
  min-height: 480px; }
  @media (max-width: 640px) {
    .OmMeg {
      flex-direction: column;
      align-items: center; } }
  .OmMeg__Portrait img {
    width: 400px; }
    @media (max-width: 640px) {
      .OmMeg__Portrait img {
        width: 100%; } }
  .OmMeg__Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 40px;
    width: 100%;
    min-height: 337px;
    margin-top: 143px;
    align-self: flex-end;
    background-color: #fff;
    margin-left: Min(calc(-400px + 30%), -50px); }
    @media (max-width: 640px) {
      .OmMeg__Card {
        align-self: inherit;
        width: auto;
        max-width: 450px;
        padding: 20px;
        margin-left: 25px;
        margin-right: 25px;
        margin-top: -100px; } }
    .OmMeg__Card p {
      margin: 0;
      color: #4c4c4c;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 10px; }
      @media (max-width: 640px) {
        .OmMeg__Card p {
          font-size: 14px;
          line-height: 22px; } }
  .OmMeg__Name {
    font-weight: 700;
    margin-bottom: 20px; }
  .OmMeg__Title {
    color: #2b29a4;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: -5px; }
    @media (max-width: 640px) {
      .OmMeg__Title {
        font-size: 32px; } }
  .OmMeg--hide-portrait .OmMeg__Portrait {
    display: none; }
  .OmMeg--hide-portrait .OmMeg__Card {
    margin: 0; }

.KontaktSection {
  background-color: #2b29a4;
  position: relative; }

.Kontakt {
  display: flex;
  padding: 180px 0;
  justify-content: center;
  gap: 200px; }
  @media (max-width: 1024px) {
    .Kontakt {
      gap: 120px; } }
  @media (max-width: 768px) {
    .Kontakt {
      gap: 0;
      justify-content: space-around; } }
  @media (max-width: 640px) {
    .Kontakt {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 80px;
      padding-left: 20px;
      padding-right: 20px; } }
  .Kontakt__ConsultationContainer {
    display: flex;
    flex-direction: column;
    gap: 16px; }
  .Kontakt__ConsultationFormContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 600px; }
  .Kontakt__ConsultationFormButtons {
    display: flex;
    justify-content: space-between;
    gap: 16px; }
    .Kontakt__ConsultationFormButtons .Kontakt__Button--disabled {
      background-color: #b4b4db;
      cursor: default; }
      .Kontakt__ConsultationFormButtons .Kontakt__Button--disabled:hover {
        background-color: #b4b4db; }
    @media (max-width: 640px) {
      .Kontakt__ConsultationFormButtons .Kontakt__Button {
        font-size: 18px;
        width: 120px;
        padding: 14px; } }
  .Kontakt__Button, .Kontakt__EmailButton {
    background: #fff;
    color: #000;
    font-weight: 500; }
    .Kontakt__Button:hover, .Kontakt__EmailButton:hover {
      background: #d6e6fe; }
  .Kontakt__EmailButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px; }
  .Kontakt__Title {
    color: #fff;
    font-size: 40px;
    font-weight: 700; }
    @media (max-width: 640px) {
      .Kontakt__Title {
        font-size: 32px; } }
  .Kontakt__Subtext {
    color: #fff;
    font-size: 20px;
    line-height: 35px; }
    @media (max-width: 640px) {
      .Kontakt__Subtext {
        font-size: 18px; } }
  .Kontakt__StatusText {
    background-color: #36ff9a;
    padding: 12px;
    border-radius: 5px; }
    .Kontakt__StatusText--success {
      background-color: #36ff9a; }
    .Kontakt__StatusText--error {
      background-color: #ff8f8f; }
  .Kontakt__DuoBox {
    position: absolute;
    top: 0;
    left: 0; }
    .Kontakt__DuoBox .InnerBox {
      position: absolute; }
      .Kontakt__DuoBox .InnerBox:nth-child(1) {
        width: 100px;
        height: 100px;
        background-color: #000; }
      .Kontakt__DuoBox .InnerBox:nth-child(2) {
        top: 100px;
        left: 100px;
        width: 50px;
        height: 50px;
        background-color: #fff; }
  .Kontakt__TrioBox {
    position: absolute;
    bottom: 150px;
    left: 0; }
    .Kontakt__TrioBox .InnerBox {
      position: absolute; }
      .Kontakt__TrioBox .InnerBox:nth-child(1) {
        width: 100px;
        height: 100px;
        background-color: rgba(255, 255, 255, 0.3); }
      .Kontakt__TrioBox .InnerBox:nth-child(2) {
        top: 100px;
        left: 100px;
        width: 50px;
        height: 50px;
        background-color: #000; }
      .Kontakt__TrioBox .InnerBox:nth-child(3) {
        top: 70px;
        left: 150px;
        width: 30px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.3); }

.AttesterSection {
  background-color: #000;
  color: #fff;
  padding: 80px 0; }

@media (max-width: 768px) {
  .Attester {
    padding: 0 20px; } }

.Attester__Title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 80px; }
  @media (max-width: 768px) {
    .Attester__Title {
      font-size: 32px;
      margin-bottom: 20px; } }

.Attester__Card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 720px;
  min-height: 375px;
  padding: 40px;
  background: #191919; }

.Attester__StarContainer {
  display: flex;
  gap: 8px; }
  .Attester__StarContainer img {
    width: 24px;
    height: 24px; }

.Attester__Text {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  margin: auto 0;
  padding: 16px 0; }

.Attester__Author {
  color: #fff;
  font-size: 16px;
  font-weight: 400; }

.Attester__Seperator {
  width: 100%;
  height: 1px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 51.08%, rgba(255, 255, 255, 0) 100%);
  margin-bottom: 24px; }

.Attester__CardBottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  @media (max-width: 768px) {
    .Attester__CardBottomContainer {
      flex-direction: column;
      gap: 20px; } }

.Attester__NavContainer {
  display: flex;
  gap: 8px; }

.Attester__NavArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border-radius: 40px;
  border: 1px solid #fff;
  cursor: pointer; }
  .Attester__NavArrow--left img {
    transform: scaleX(-100%); }
